:root {
  --color-darkblue: darkblue;
  --color-darkmagenta: darkmagenta;
  --color-midnightblue: midnightblue;
  --color-example: darkgrey;
}

[data-bs-theme=dark] {
  --color-darkmagenta: #b17eb1;
  --color-midnightblue: #97c2e7;
  --color-darkblue: steelblue;
  --color-example: gray;
}

html, body, .root {height: 100%;}
body {margin: 0;font-family: Arial, Helvetica, sans-serif;}
footer .btn, a {text-decoration: none;}
a:hover {text-decoration: underline;}

textarea, input[type="text"], input[type="button"], input[type="submit"] {
  -webkit-appearance: none; border-radius: 0; outline: none; }

.content {max-width: 800px; margin: 0 auto; padding: 5px; display: flex; flex-direction: column; min-height: 100%;}

#search {position:relative;}
.search-input {margin: 0 0 0 0; display: flex; align-items: stretch; height: 35px}
.search-input input[type=text] {flex: 2; margin: 0; padding: 0 0 0 5px; width: 100%;
  border: 1px solid lightgrey; border-right: none; font-size: 1.2rem;}
[data-bs-theme=dark] .search-input input[type=text] {border-color: gray; background-color: var(--bs-body-bg);}
.search-input .btn {border: none; border-radius: 0; cursor: pointer; }
.search-input .button-search {color:white; background: red; margin: 0; padding:3px 10px;font-size: 1.2rem;}
.search-input .button-search:disabled {background: grey;}
.search-input .button-search:active {background: black;}
.search-input .button-search-small {display:none; padding: 5px 8px; font-size: 25px;}
.search-input .button-control {color:grey; padding: 4px 0px 4px 7px;
  font-size: 25px; line-height: 0; border: 1px solid lightgrey; border-right: none; border-left: none;}
[data-bs-theme=dark] .search-input .button-control,
[data-bs-theme=dark] .search-input .button-control:active {border-color: gray;}
.search-input .button-funnel {padding-right: 7px;}
.search-input .button-control:active {color:grey; border: 1px solid lightgrey; border-right: none; border-left: none;}
.search-input .button-control:active svg { transform:scale(1.3);
  animation-name: search-input-button-control-active; animation-duration: 0.1s;}
@keyframes search-input-button-control-active {from {transform:scale(1);} to {transform:scale(1.3);}}

.suggestions {position: absolute; width: 100%; box-sizing: border-box; z-index: 1000;
  background: white; padding: 0; margin: 0; list-style: none; cursor: pointer;
  border: 1px solid lightgrey; border-top: none; box-shadow: 0 2px 4px rgba(0,0,0,0.1)}
[data-bs-theme=dark] .suggestions {border-color: gray; background: var(--bs-body-bg)}
.suggestions li {padding: 5px;}
.suggestions li.active {background-color: #e3e3e3;}
[data-bs-theme=dark] .suggestions li.active {background-color: gray;}

.dicts-filter {position: absolute; width: 100%; box-sizing: border-box; z-index: 1000; padding: 10px;
  background: white; border: 1px solid lightgrey; border-top: none; box-shadow: 0 2px 4px rgba(0,0,0,0.1)}
[data-bs-theme=dark] .dicts-filter {border-color: gray; background: var(--bs-body-bg)}
.dicts-filter ul {list-style: none; padding: 0; margin: 0;}
.dicts-filter li { padding: 5px 0;}
.dicts-filter li:last-child { padding-bottom: 0;}
.dicts-filter li.active {background-color: #e3e3e3;}
.dicts-filter .btn-link {vertical-align: inherit; padding: inherit;}
.dicts-filter label {cursor: pointer;}
.dicts-filter input[type="checkbox"] {cursor: pointer;}

.no-results {padding: 6px;}

.article {padding: 6px; border-bottom: 1px solid lightgrey; min-height:45px;}
[data-bs-theme=dark] .article {border-bottom-color: gray;}
.article:last-child {border-bottom: none;}
.article a {text-decoration: underline;}
.article v-hw,
.article .hw {color: var(--color-midnightblue); font-weight: bold;}
.article v-ex, .article .ex {color: var(--color-example);}
.article v-trx {
  color: darkgrey;
  font-size: 0.8em;
  font-weight:lighter;
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
}
.article .accent {color: #660000;}
[data-bs-theme=dark] .article .accent {color: inherit; filter: brightness(1.2);}
.article a .accent {color: #aa0000;}
.article v-ex .accent {color: #cca9a9;}
.article > .source {text-align: right; color: darkgrey; font-size: 0.9em; padding-top: 9px}
.article p {margin: 0;}
.article.hsbm img {max-width:100%;}
.article.grammardb em {color:darkgrey;}
.article .buttons {float: right;}
.article .buttons .btn {color: darkgray; padding: 0; outline: none; box-shadow: none; font-size:20px;}
.article highlight {background-color: #ffffc6; box-shadow: 0 0 5px 3px #ffffc6; position: relative;}
[data-bs-theme=dark] .article highlight {background-color: #353535; box-shadow: 0 0 5px 3px #353535;}
.article v-abbr {color: darkgreen;}
[data-bs-theme=dark] .article v-abbr {color: green;}
.article v-abbr.source {color: var(--color-darkmagenta);}
.article v-abbr.geo {color: teal;}
.article.tsblm ul, .article.tsblm ol {margin-bottom: 0;}
.article.tsbm [style='color: crimson'] v-abbr {color: crimson;}
.article.tsbm [style='color: midnightblue'] v-abbr {color: var(--color-midnightblue);}
.article v-abbr[data-bs-toggle="tooltip"] {cursor: default; text-decoration: underline; text-decoration-style: dotted;}
.article table, .article tr, .article td, .article th { border: 1px solid lightgrey; vertical-align: top; padding: 0.2rem;}
.article th {text-align:center; font-weight: normal; background-color: #d3d3d31f;}
.article table {border-collapse: collapse; margin: 0.5em 0;}
[data-bs-theme=dark] .article [style='color: darkblue'],
[data-bs-theme=dark] .article [style='color: midnightblue'] {color: var(--color-midnightblue) !important;}
[data-bs-theme=dark] .article [style='color: brown'] {color: #b59696 !important;}
.article v-illustration {letter-spacing: 0.4em;}

.logo {color:darkgrey; padding: 6px 0; font-weight: bold;}
.logo h1 {display: inline; font-weight: bold;}
.logo a {color:red; text-decoration: none; }

@media only screen and (max-width: 500px) {
  .search-input .button-search-small {display: inherit;}
  .search-input .button-search-wide {display: none;}
}

.letter-filter-level {border-bottom: 1px solid lightgray;}
[data-bs-theme=dark] .letter-filter-level {border-bottom-color: gray;}
.letter-filter-level:last-child {border-bottom:none;}

.pagination {flex-wrap: wrap;}
.pagination .page-item .page-link {border-radius: 0; border:0; color: darkgray; }
.pagination .page-link.disabled, .pagination .disabled > .page-link {background: none;}
.pagination .page-item.active .page-link { background-color: inherit; border-bottom:1px solid darkgray; cursor: inherit;}
